export default [
  {
    title: "重磅消息：优气宝、优气达小程序即将上线！",
    cover: "/staticImg/news1.png",
    content: `<p>你是否在使用瓶装液化石油气呢？液化石油气在给人们生活带来便利的同时，也存在诸多问题。</p>
    <p>》》对于液化气用户来说，常常面临突然断气的尴尬、以及燃气泄漏的隐忧&hellip;&hellip;</p>
    <p>》》对于送气企业，依然采用传统被动的经营方式，闲时段资源浪费，忙时段资源分配不到位，疲于应对黑气的竞争，又要接受监管单位的检查&hellip;&hellip;</p>
    <p>》》对于监管单位：燃气安全事故频发、民众安全意识淡薄、非法经营监管困难&hellip;&hellip;</p>
    <p>&nbsp;</p>
    <p>深圳多知能源物联有限公司推出优气物联网解决方案，旨在优化送气方式，保障用气安全，采用物联网最新技术解决以上痛点。</p>
    <p>&nbsp;</p>
    <img src="/staticImg/news2.png" />
    <p>接下来让我们一步步揭开优气物联网解决方案的庐山真面目：</p>
    <img src="/staticImg/news5.png" />
    <img src="/staticImg/news6.png" />
    <img src="/staticImg/news7.png" />
    <img src="/staticImg/news8.png" />
    <p>“深圳多知能源物联有限公司”致力于“让生活用气更美好”，创始团队在“嵌入式技术开发行业”具备10年以上的从业经验；公司以物联网发展趋势为导向，专注于“液化石油气LPG”的物联网应用软件开发、终端业务管理以及配套软硬件产品的研发、生产、销售和服务。</p>`,
    time_y: "2020",
    time_m: "09",
    time_d: "15",
    type: "companyDynamics",
    id: 1
  },
  {
    title: "湖北十堰燃气爆炸事故令人痛心",
    cover: "/staticImg/news3.png",
    content: `<p>湖北省政府新闻办公室14日晚在十堰市召开新闻发布会：湖北省十堰市燃气爆炸事故已致25人死亡，现场搜救和清理工作仍在进行。</p>
    <img src="/staticImg/news9.png" />
    <p>湖北省应急管理厅厅长施政说，湖北省十堰市张湾区&ldquo;6&middot;13&rdquo;重大燃气爆炸事故调查组已成立，事故调查组成员由省应急管理厅、省纪委监委、省住建厅、省公安厅、省总工会、省国资委、省市场监管局、省消防总队、国家监委驻东风汽车集团有限公司、十堰市纪委有关负责同志组成。</p>
    <p>湖北省卫健委主任涂远超说，各定点医院按照一个伤者、一个专班、一个方案的要求，组织开展医疗救治工作，对急重危症患者进行集中专家会诊。来自本地和外地支援医院的60余名专家，逐一进行床头会诊，对所有病人实行多学科协作诊疗，及时排除病情恶化因素。抽调58名心理专家组成5个小组，积极做好所有伤员和罹难者家属的心理抚慰工作。</p>
    <p>湖北省消防救援总队副总队长杨全德说，事故发生后，湖北消防救援总队迅速调集十堰、武汉、襄阳、随州4个支队共45台消防车、210名指战员赶赴现场，利用搜救犬、生命探测仪等多种手段全力搜救被困人员。在现场设置9名安全员、2处安全哨，组织燃气、建筑等专家对气体浓度、危险源、泄漏部位和建筑结构进行实时监测评估，坚决预防次生灾害发生，在确保安全情况下选派精干力量参与核心区域救援。</p>
    <p>施政说，下一步，将继续做好现场搜索救援工作，确保不漏一人，不留盲区死角，不到情况完全清楚决不收兵；防范现场次生灾害，会同十堰市地毯式排查整治事故隐患，扎实做好水、电、燃气、化工等管线检修除险和安全供应，坚决防止坍塌、二次爆炸等情况；尽快查明事故原因，开展全省安全生产大检查，系统开展全领域、全行业拉网式安全隐患大排查、大整治。（记者李伟）</p>
    <p>来源：新华社</p>`,
    time_y: "2021",
    time_m: "06",
    time_d: "15",
    type: "industryDynamics",
    id: 2
  },
  {
    title: "2020年中国LPG市场发展预测",
    cover: "/staticImg/news4.jpg",
    content: `<p><strong>LPG市场现状</strong></p>
    <p>中国LPG供应基本来自主营炼厂商品量、MTO副产LPG、油田伴生气、净进口量等几个方面。我国国产供应及进口量都处于逐年上升的趋势，近几年来，由于基数的不断增大，增长速度有所放缓。随着地方炼厂地炼原油进口配额陆续增加，且13年开始炼厂加速分离原料气，我国国产供应直线上升。在资源流通方面，华南（包含广西）和华东资源向华中、西部流通，山东资源向华北、东北流通。华南地区为我国最大的资源进口区域，而山东地区为最大的国产资源供应地区。</p>
    <img src="/staticImg/news10.png" />
    <p><strong>挑战与机遇</strong></p>
    <p>在国内需求方面，我国需求增量主要来自化工需求和炼厂自用需求，尤其化工增量幅度明显。从2018年的数据来看，我国燃烧需求在2350万吨左右，更多数据在隆众资讯查看其中华南地区消费量排第一位，华东及华北紧随其后。从中国燃气旗下26家终端样本公司LPG销售量变化趋势来看，各类型城市LPG消费量增速各不同，省会等大城市LPG消费总量负增长，小型城市及旅游城市等地区消费量仍然维持正增长。</p>
    <p>后期LPG市场挑战与机遇并存。挑战主要在于，相关产品天然气的持续冲击，因国家大力推动天然气管网建设及农村煤改气，LPG民用需求已经在向农村及偏远地区转移。而LPG行业本身集中度低，竞争激烈等问题也日益凸显，近年来LPG安全事故也时有发生，引起社会各界的广泛重视。而机遇是与挑战并存的，我国拥有丰富的LPG资源，且随着炼厂原油加工能力不断提升，作为副产的LPG产量势必不断增加。随着服务业的不断发展，美丽乡村的建设及我国大力发展夜市经济，也会提升LPG的需求。科技是推动一切进步的生产力，</p>
    <p>在云数据、物联网及区块链的带动下，LPG行业有望迎来新生。而在运输方面，带泵槽车、小储罐、及微管网的投用又将增加LPG运输及储存的便捷性，为终端提供更多便利。</p>
    <img src="/staticImg/news11.jpg" />
    <p><strong>LPG市场未来趋势预测</strong></p>
    <p>随着原油加工能力的不断提升，我国国内LPG产量处于持续增长的趋势，平均增速在5%左右。而由于国内需求增速要高于产量增速，且液化气自用量不断加大，商品量逐年萎缩，较大的供应缺口仍需依靠进口气来填补，使得国内LPG进口量不断增加，净进口依存度平均在30%左右。国内LPG主要产地为山东地区，2019年山东地区LPG产量占全国的34%。按企业性质来说，国内LPG供应主要来自主营企业。</p>
    <p>在国内需求方面，国内LPG主要下游还是以民用为主，需求量占国内供应量的一半以上，而需求增速来看，深加工需求增速高于民用需求增速，深加工目前LPG主要下游还是以油品为主，2019年油品需求量占到深加工总需求的73%，其中MTBE因传统装置较多，其需求量高达43%更多数据在隆众资讯查看 。但从利润角度来看，虽国内LPG主要下游是油品方向，但其装置平均利润却仅在113元/吨左右。而化工方向装置均利润在1270元/吨左右。</p>
    <p>2020年国内LPG市场发展预测，国内产量依然处于增长的趋势，同时国内LPG民用需求方面还是处于缓慢上升的姿态。而深加工方面，虽2020年仍有MTBE和烷基化装置投产，但均为炼化一体化企业或厂家新增配套装置，并无独立性装置企业投产，将使原料气（烯烃碳四）市场商品量继续收窄，因此2020年油品深加工装置利润将有收窄风险。而化工方面，仅丙烷脱氢装置就有400万吨的投产计划，将会使国内丙烷进口量继续大幅增加，对于国际及国内贸易商企业来说，有望迎来新的机遇</p>`,
    time_y: "2020",
    time_m: "08",
    time_d: "19",
    type: "industryDynamics",
    id: 3
  }
]