<template>
  <div class="page">
    <headerImg :src="banner" height="640" />
    <div class="content">
      <div class="title">{{$t('nav.'+detail.type)}}</div>
      <div class="text-content">
        <div class="text-title">{{detail.title}}</div>
        <div class="text-html" v-html="detail.content"></div>
      </div>
    </div>
    <div style="width: 1200px;margin: 0 auto;line-height: 50px;height: 50px;font-size: 14px;">
      <a :title="news[selectIndex - 1].title" :href="'/consultingTrends/detail/?id=' + news[selectIndex - 1].id" style="color: #b6b9ba; float: left;cursor: pointer;" v-if="selectIndex != 0">上一篇：<span style="color: #535353">{{news[selectIndex - 1].title}}</span></a>
      <a :title="news[selectIndex + 1].title" :href="'/consultingTrends/detail/?id=' + news[selectIndex + 1].id" style="color: #b6b9ba; float: right;cursor: pointer;" v-if="news[selectIndex + 1]">下一篇：<span style="color: #535353">{{news[selectIndex + 1].title}}</span></a>
    </div>
  </div>
</template>

<script>
import banner from "@/assets/images/banner3.jpg"
import headerImg from "@/components/headerImg.vue"
import news from "@/assets/hypocritical/news.js"
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerImg},
  data(){
    return {
      banner,
      news
    }
  },
  computed: {
    detail(){
      return news.find(it => it.id == this.$route.query.id)
    },
    selectIndex(){
      return news.findIndex(it => it.id == this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  background: #ecf1f3;
  .content{
    .title{
      color: #535353;
      line-height: 60px;
      font-size: 40px;
      padding: 20px 0;
      text-align: left;
    }
    .text-content{
      background: white;
      font-size: 26px;
      color: #131313;
      padding: 20px 0;
    }
    .text-html{
      color: #424242;
      font-size: 16px;
      text-align: left;
      line-height: 24px;
      padding: 20px 30px;
      ::v-deep p{
        text-indent: 36px;
      }
      ::v-deep img{
        width: 60%;
        margin-left: 20%;
      }
    }
  }
}

</style>